import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_EMPLOYEES, ADMIN_USERS_STAFF_DATA_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import PageContent from 'Components/layout/panel/PageContent';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import NotFound from 'Components/layout/panel/NotFound';
import StaffDataForm from 'Components/admin/users/StaffDataForm';
import { TAB_LIST, TAB_STAFF } from 'Components/pages/admin/Employees/component';

export default class AdminUsersStaffDataManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        staffData: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { staffData, location, history } = this.props;

        if (!staffData || (staffData && staffData.isLoading)) {
            return (<Spinner />);
        }

        if (staffData && staffData.isLoaded && !staffData.data) {
            return (<NotFound />);
        }

        return (
            <StyledComponent
                className="page-staf-data-manage"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_EMPLOYEES.path, {}, { tab: TAB_LIST }),
                        label: 'Pracownicy',
                    }, {
                        to: withVariables(ADMIN_EMPLOYEES.path, {}, { tab: TAB_STAFF }),
                        label: 'Kadry',
                    }, {
                        to: withVariables(ADMIN_USERS_STAFF_DATA_MANAGE.path, { id: staffData.data.user.id }),
                        label: `Dane kadrowe: ${staffData.data.user.name} ${staffData.data.user.surname}`,
                    }]}
                >
                    <ViewHeader
                        title="Edytuj dane kadrowe"
                        subtitle={`${staffData.data.user.name} ${staffData.data.user.surname}`}
                    />
                    <StaffDataForm
                        location={location}
                        history={history}
                        data={staffData.data}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}