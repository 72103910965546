import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { USER_ROLE_TRAINER } from 'Consts/userRoles';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class PanelUsersStaffDataForm extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            set: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: this.props.data || {},  //eslint-disable-line react/destructuring-assignment
    };
    contractTypes = [
        {
            label: 'Umowa B2B',
            value: 'B2B',
        }, {
            label: 'Umowa zlecenie',
            value: 'CIVIL',
        }, {
            label: 'Umowa zlecenie (stiudent)',
            value: 'CIVIL_STUDENT',
        }, {
            label: 'Umowa o dzieło 50% KUP',
            value: 'SPECIFIC_TASK_50',
        }, {
            label: 'Umowa o dzieło 20% KUP',
            value: 'SPECIFIC_TASK_20',
        }, {
            label: 'Umowa zlecenie + UoP',
            value: 'EMPLOYMENT_AND_CIVIL',
        },
    ];

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...data,
                },
            }));
        }
    }

    loadUsers = (query = '') => {
        const { actions } = this.props;

        return actions.listUsers({
            page: 1,
            perPage: 100,
            search: typeof query === 'string'
                ? query
                : undefined,
            role: USER_ROLE_TRAINER,
            enabled: true,
        });
    }

    render() {
        const { location, history, actions } = this.props;
        const { formState } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="panel-users-staff-data-form"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(formState.id),
                        label: 'id',
                        value: formState.id,
                    }, {
                        visible: Boolean(formState.user),
                        label: 'Imię i nazwisko',
                        value: formState.user
                            ? `${formState.user.name} ${formState.user.surname}`
                            : null,
                    }]}
                    forms={[{
                        title: 'Dane kadrowe',
                        name: 'editor',
                        submitAction: data => actions.set({
                            ...data,
                            userId: data.userId
                                ? data.userId.value
                                : null,
                            contractType: data.contractType
                                ? data.contractType.value
                                : null,
                        }),
                        data: {
                            ...formState,
                            userId: !formState.userId && formState.user ? {
                                label: `${formState.user.surname} ${formState.user.name} (${formState.user.email})`,
                                value: formState.user.id,
                            } : formState.userId,
                            contractCurrentSignedAt: formState.contractCurrentSignedAt
                                ? moment(formState.contractCurrentSignedAt).toDate()
                                : null,
                            employedAt: formState.employedAt
                                ? moment(formState.employedAt).toDate()
                                : null,
                            noticeReceiptAt: formState.noticeReceiptAt ?
                                moment(formState.noticeReceiptAt).toDate()
                                : null,
                            noticeValidTo: formState.noticeValidTo
                                ? moment(formState.noticeValidTo).toDate()
                                : null,
                            studentCardValidTo: formState.studentCardValidTo
                                ? moment(formState.studentCardValidTo).toDate()
                                : null,
                            ocValidTo: formState.ocValidTo
                                ? moment(formState.ocValidTo).toDate()
                                : null,
                        },
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'select',
                            name: 'userId',
                            label: 'Użytkownik',
                            inputProps: {
                                disabled: true,
                                dynamic: true,
                                onLoad: query => this.loadUsers(query),
                                onMapResponse: response => response.payload.users.elements,
                                onMapOption: user => ({
                                    value: user.id,
                                    label: `${user.surname} ${user.name} (${user.email})`,
                                }),
                            },
                        }, {
                            type: 'select',
                            name: 'contractType',
                            label: 'Typ umowy',
                            options: this.contractTypes,
                        }, {
                            type: 'datePicker',
                            name: 'contractCurrentSignedAt',
                            label: 'Data podpisania',
                        }, {
                            type: 'input',
                            name: 'pesel',
                            label: 'Pesel',
                            inputProps: {
                                mask: '99999999999',
                            },
                        }, {
                            type: 'input',
                            name: 'address',
                            label: 'Adres',
                        }, {
                            type: 'input',
                            name: 'city',
                            label: 'Miasto',
                        }, {
                            type: 'input',
                            name: 'postalCode',
                            label: 'Kod pocztowy',
                            inputProps: {
                                mask: '99-999',
                            },
                        }, {
                            type: 'input',
                            name: 'iban',
                            label: 'Numer konta bankowego (IBAN)',
                            inputProps: {
                                mask: 'PL99 9999 9999 9999 9999 9999 9999',
                            },
                        }, {
                            type: 'datePicker',
                            name: 'employedAt',
                            label: 'Data zatrudnienia',
                        }, {
                            type: 'datePicker',
                            name: 'noticeReceiptAt',
                            label: 'Data otrzymania wypowiedzenia',
                        }, {
                            type: 'datePicker',
                            name: 'noticeValidTo',
                            label: 'Data końca okresu wypowiedzenia',
                        }, {
                            type: 'switch',
                            name: 'flagCertificateOfTheProfession',
                            label: 'Czy ma certyfikat uprawnienia do zawodu?',
                        }, {
                            type: 'switch',
                            name: 'flagMedicalExamination',
                            label: 'Czy ma badania lekarskie?',
                        }, {
                            type: 'switch',
                            name: 'flagZusStatements',
                            label: 'Czy ma badania oświadczenie ZUS?',
                        }, {
                            type: 'switch',
                            name: 'flagStudentCard',
                            label: 'Czy ma legitymacje studencką?',
                        }, {
                            isVisible: Boolean(formState.flagStudentCard),
                            type: 'datePicker',
                            name: 'studentCardValidTo',
                            label: 'Data ważności legitymacji studenckiej',
                        }, {
                            type: 'switch',
                            name: 'flagConsentUseImage',
                            label: 'Czy jest zgoda na wykorzystanie wizerunku?',
                        }, {
                            type: 'switch',
                            name: 'flagConsentPersonalData',
                            label: 'Czy jest zgoda na przetwarzanie danych osobowych?',
                        }, {
                            type: 'switch',
                            name: 'flagOc',
                            label: 'Czy ma OC?',
                        }, {
                            isVisible: Boolean(formState.flagOc),
                            type: 'datePicker',
                            name: 'ocValidTo',
                            label: 'Data ważności OC',
                        }, {
                            type: 'input',
                            name: 'gdriveUrl',
                            label: 'Link do Google Drive',
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}