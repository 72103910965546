import { connect } from 'react-redux';
import Component from './component';

import {
    set,
} from 'Redux/modules/admin/staffData/actions';

import {
    list as listUsers,
} from 'Redux/modules/admin/users/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            set: dispatch(set),
            listUsers: dispatch(listUsers),
        },
    }),
)(Component);