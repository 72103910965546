import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header {
            .headline {
                font-size: 3em;
                text-align: left;
                color: ${variables.dpc_fontDark};
                font-weight: 300;
            }
            .subheadline {
                font-size: 1.2em;
                text-align: left;
                color: ${variables.dpc_fontDark};
                font-weight: 600;
                margin-top: .5em;
            }
        }
        .controls {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .button {
                margin-right: 1em;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        &.style-version-1 {
            
        }

        &.style-version-2 {
            .header {
                .headline {
                    color: ${variables.dpc_borderDark};
                    font-size: 2.2em;
                    font-weight: 500;
                }
                .subheadline {
                    font-size: .9em;
                    color: #C5C5C5;
                    font-weight: 300;
                }
            }
        }
    `;
