import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layout/Button';

const PanelViewHeader = ({ title, subtitle, controls, styleVersion }) => (
    <StyledComponent
        styles={require('./styles')}
        className={classnames({
            'panel-view-header': true,
            [`style-version-${styleVersion}`]: true,
        })}
    >
        <div className="header">
            <h2 className="headline">{title}</h2>
            {subtitle && <h3 className="subheadline">{subtitle}</h3>}
        </div>
        {controls.length
            ? (
                <div className="controls">
                    {controls.map(control => (
                        <Button
                            key={control.key}
                            {...control}
                        />
                    ))}
                </div>
            )
            : null
        }
    </StyledComponent>
);

PanelViewHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    controls: PropTypes.arrayOf(
        PropTypes.object.isRequired,
    ),
    styleVersion: PropTypes.oneOf([
        1, 2,
    ]),
};
PanelViewHeader.defaultProps = {
    subtitle: null,
    controls: [],
    styleVersion: 1,
};
export default PanelViewHeader;
